<template>
	<footer
		id="footer"
		class="sticky w-full bg-white border-t-2 top-full"
	>
		<div class="max-w-6xl mx-auto md:px-3 lg:flex">
			<div
				v-if="isMobile"
				class="relative h-[48px]"
			>
				<button
					class="absolute transform -translate-x-1/2 -translate-y-1/2 right-1 top-1/2"
					@click="toggleFooter"
				>
					<img
						src="@/assets/icons/arrow-down-black.svg"
						class="my-auto"
						:class="{ 'transform rotate-180': expandedFooter }"
						alt="collapse footer arrow"
						width="16"
						height="16"
					>
				</button>
				<div
					class="w-full text-left border-b-2 border-gray-200 md:border-b-0"
					@click="toggleFooter"
				>
					<div class="flex h-full pl-3 md:hidden">
						<div v-track-event.click="LOGO_FOOTER_CLICK">
							<router-link to="/">
								<WwLogo
									full
									class="my-2"
								/>
							</router-link>
						</div>
					</div>
				</div>
			</div>
			<!-- End Mobile Footer Accordion Trigger -->
			<section
				v-show="showFooterContent"
				class="grid w-full grid-cols-2 gap-3 md:px-3 md:grid-cols-4"
			>
				<!-- Start Desktop WW Branding and Social Links -->
				<div class="hidden md:block">
					<div
						v-track-event.click="LOGO_FOOTER_CLICK"
						class="px-3 pt-2 md:px-0"
					>
						<router-link to="/">
							<WwLogo
								full
								class="my-2"
							/>
						</router-link>
					</div>
					<FooterSocialLinks
						:links="SOCIAL_LINKS"
						class="pt-2 pl-9"
					/>
				</div>
				<!-- End Desktop WW Branding and Social Links -->
				<FooterNavigationLinks
					:links="COMPANY_LINKS"
					title="Company"
				/>
				<FooterNavigationLinks
					:links="BUSINESS_LINKS"
					title="Business"
				/>
				<NewsletterSignupCTA
					v-if="!isMobile"
					class="w-full col-span-2 px-3 pt-4 font-bold border-t-2 border-gray-200 md:col-span-1 md:pb-6 md:justify-self-end md:border-none md:px-0 md:w-48"
				/>
			</section>
		</div>
		<NewsletterSignupCTA
			v-if="showFooterContent || isMobile"
			:class="[
				{ 'border-gray-200 border-t-2': showFooterContent },
				'w-full col-span-2 px-3 pt-4 font-bold md:hidden md:col-span-1 md:pb-6 md:justify-self-end md:border-none md:px-0 md:w-48'
			]"
		/>
		<FooterSocialLinks
			v-if="showFooterContent || isMobile"
			:links="SOCIAL_LINKS"
			class="justify-center w-full py-6 md:hidden"
		/>
		<FooterLegalLinks v-if="showFooterContent || isMobile" />
		<WwChat
			v-if="showChat"
			class="z-10"
		/>
	</footer>
</template>

<script defer>
import { mapGetters, mapState } from 'vuex'

import WwLogo from '@/components/multiUse/WwLogo.vue'
import { BUSINESS_LINKS, COMPANY_LINKS, SOCIAL_LINKS } from '@/constants/footerLinks.js'
import { LOGO_FOOTER_CLICK } from '@/constants/logging/clickEvents.js'

export default {
	components: {
		WwLogo,
		NewsletterSignupCTA: () => import('@/components/marketing/NewsletterSignupCTA.vue'),
		WwChat: () => import('@/components/singleUse/WwChat.vue'),
		FooterSocialLinks: () => import('@/components/footer/FooterSocialLinks.vue'),
		FooterNavigationLinks: () => import('@/components/footer/FooterNavigationLinks.vue'),
		FooterLegalLinks: () => import('@/components/footer/FooterLegalLinks.vue')
	},
	data() {
		return {
			isMounted: false,
			COMPANY_LINKS,
			BUSINESS_LINKS,
			SOCIAL_LINKS,
			expandedFooter: false,
			LOGO_FOOTER_CLICK
		}
	},
	computed: {
		...mapGetters([ 'isMobile' ]),
		userAgent() {
			return navigator?.userAgent
		},
		isBot() {
			return (/crawl|googlebot|slurp|spider|bingbot|tracker|click|parser|applebot|yandex|semrushbot|ahrefsbot|insights|lighthouse/.test(this.userAgent.toLowerCase()))
		},
		showChat() {
			return this.isMounted && !this.isBot && this.auth?.showChat
		},
		...mapState({
			auth: state => state.auth
		}),
		logoWidth() {
			return this.isMobile ? 125 : 200
		},
		showFooterContent() {
			return this.expandedFooter || !this.isMobile
		}
	},
	mounted() {
		this.isMounted = true
	},
	methods: {
		async toggleFooter() {
			await this.toggleExpanded()
			window.scrollTo(0, document.body.scrollHeight)
		},
		toggleExpanded() {
			this.expandedFooter = !this.expandedFooter
		}
	}
}
</script>
