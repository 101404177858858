export const COMPANY_LINKS = [
	{ name: 'About', link: 'about' },
	{ name: 'Contact', link: 'contact' },
	{ name: 'Blog', link: 'blogIndex' },
	{ name: 'Advocacy', link: 'advocacy' }
]

export const BUSINESS_LINKS = [
	{ name: 'Business Solutions', link: 'tourIndex' },
	{ name: 'Add a business ', link: 'tourIndex' },
	{ name: 'Partners', link: 'partners' },
	{ name: 'Advertising', link: 'advertising' }
]

export const SOCIAL_LINKS = [
	{
		name: 'Instagram',
		url: 'https://www.instagram.com/wheresweed/',
		imgSrc: require('@/assets/icons/instagram2.svg'),
		alt: 'Instagram Link'
	},
	{
		name: 'Facebook',
		url: 'https://www.facebook.com/WheresWeedcom-105578841911640',
		imgSrc: require('@/assets/icons/facebook2.svg'),
		alt: 'Facebook Link'
	},
	{
		name: 'Twitter',
		url: 'https://twitter.com/wheresweed',
		imgSrc: require('@/assets/icons/twitter2.svg'),
		alt: 'Twitter Link'
	},
	{
		name: 'LinkedIn',
		url: 'https://www.linkedin.com/company/where%27s-weed',
		imgSrc: require('@/assets/icons/linkedin.svg'),
		alt: 'Linkedin Link'
	}
]
